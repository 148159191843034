import { Link } from 'gatsby';
import React from 'react';

const Cabinet = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Cabinet</h1>
      </div>

      <div id="img-wrap">
        <img
          src="/img/lemire-cabinet-large.jpg"
          alt="Cabinet"
          title="Cabinet"
        />
      </div>

      <div id="info">
        <div id="texte" className="cabinet">
          <p>
            Fondée en <span className="accent">1976</span> par Me Claude Lemire,
            Lemire Lemire avocats s.e.n.c.r.l. s'oblige à fournir à sa clientèle
            des services d'une qualité irréprochable.
          </p>
          <p>
            Grâce à notre expertise en{' '}
            <span className="accent2">Droit de la famille</span> (
            <Link to="/competences/separation-divorce">
              séparation - divorce
            </Link>
            , <Link to="/competences/garde-enfant">garde d'enfants</Link>),{' '}
            <Link to="/competences/construction">
              <span className="accent2">Droit de la construction</span>
            </Link>
            ,{' '}
            <Link to="/competences/responsabilite">
              <span className="accent2">Droit de la responsabilité</span>
            </Link>
            ,{' '}
            <Link to="/competences/immobilier">
              <span className="accent2">Droit immobilier</span>
            </Link>{' '}
            et{' '}
            <Link to="/competences/assurances">
              <span className="accent2">Droit des assurances</span>
            </Link>
            , nous offrons des conseils juridiques et des solutions qui
            répondent aux attentes de nos clients.
          </p>
          <p>
            Dans un environnement de forte concurrence, nous accordons une
            grande importance aux liens privilégiés que nous entretenons avec
            nos clients. L'enrichissement et le développement constant que
            connaît notre cabinet sont attribuables à la haute qualité de nos
            services professionnels.
          </p>
        </div>
      </div>
    </>
  );
};

Cabinet.propTypes = {};

export default Cabinet;
