import React from 'react';

import { PAGES } from '../utils/utils';
import Cabinet from '../components/fr/cabinet';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CabinetPage = () => (
  <Layout page={PAGES.CABINET}>
    <SEO title="Cabinet" />
    <Cabinet />
  </Layout>
);

export default CabinetPage;
